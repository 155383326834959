export let baseUrl = window.location.origin
if(window.location.hostname === 'localhost' || window.location.hostname === '192.168.4.154') baseUrl = 'http://localhost:9000';
export const cms = 'https://cockpit.dornsberg.net'

// PAGE TRANSITION //
export const pageTransition = {
	initial: {
		x: '100vw',
		opacity: 0
	},
	in: {
		x: 0,
		opacity: 1
	},
	out: {
		x: '100vw',
		opacity: 0
	},
	transition: {
		duration: .25,
		ease: 'linear'
	},
	style: {
		position: "absolute",
		width: '100%',
		padding: '5%'
	}
};

// TRANSLATIONS //

export let lang = navigator.language.substring(0,2) || navigator.userLanguage.substring(0,2);
// default lang is en
if (lang !== 'de' && lang !== 'it' && lang !== 'en') lang = 'en';
// change html lang tag
document.documentElement.lang = lang;
export const salutation = {
	de: 'Liebe Familie ',
	it: 'Cara famiglia ',
	en: 'Dear family '
}
export const introText = {
	de: 'Bevor Sie Ihre Anreise antreten, möchten wir sämtliche Vorkehrungen treffen, damit Ihr Aufenthalt bei uns im Dornsberg Panoramic Apartments zu einem unvergesslichen Erlebnis wird. Teilen Sie uns vorab Ihre Wünsche und Anliegen mit – wir kümmern uns gerne darum!',
	it: 'Prima della vostra partenza, desideriamo organizzarci al fine di rendere il soggiorno al Dornsberg Panoramic Apartments un\'esperienza indimenticabile. Fateci sapere i vostri desideri e richieste, ce ne occuperemo volentieri!',
	en: 'Before you start your journey to visit us, we want to take all precautions to make your stay at the Dornsberg Panoramic Apartments unforgettable. Inform us in advance about your wishes and requests – we will take care of it.'
}
export const letsGo = {
	de: 'Los geht\'s',
	it: 'Avanti',
	en: 'Let\'s go'
}
export const continueLabel = {
	de: 'Weiter',
	it: 'Avanti',
	en: 'Continue'
}
export const submitLabel = {
	de: 'Absenden',
	it: 'Invia',
	en: 'Submit'
}
export const backLabel = {
	de: 'Zurück',
	it: 'Indietro',
	en: 'Back'
}
export const selected = {
	de: 'Ausgewählt',
	it: 'Selezionato',
	en: 'Selected'
}
export const complete = {
	de: 'Vielen Dank für die wertvollen Informationen, die Sie uns zugeschickt haben! Wir werden diese nutzen, um sorgsam Ihre schönste Zeit des Jahres vorzubereiten.<br/><br/>Bis bald im Dornsberg Panoramic Apartments,<br/><br/>Ihre Familie Illmer',
	it: 'Vi ringraziamo per le preziose informazioni che ci ha fornito!<br/><br/>A presto al Dornsberg Panoramic Apartments,<br/><br/>famiglia Illmer',
	en: 'Thank you for sharing your wishes!<br/><br/>See you soon at the Dornsberg Panoramic Apartments,<br/><br/>the Illmer family'
}
export const bookingCode = {
	de: 'Buchungsnummer',
	it: 'Codice di prenotazione',
	en: 'Booking code'
}
export const nameLabel = {
	de: 'Name',
	it: 'Nome',
	en: 'Name'
}
export const arrivalLabel = {
	de: 'Ankunft',
	it: 'Arrivo',
	en: 'Arrival'
}
export const emailLabel = {
	de: 'E-Mail',
	it: 'E-mail',
	en: 'E-mail'
}
export const bookingCodeExpl = {
	de: 'Ihre Buchungsnummer finden Sie auf der Buchungsbestätigung',
	it: 'Il codice di prenotazione le trova sulla conferma della prenotazione',
	en: 'You can find your booking number on the booking confirmation'
}
export const bookingMailExpl = {
	de: 'Nur jene E-Mail-Adresse, die Sie bei der Buchung angegeben haben, ist gültig',
	it: 'Solo l\'indirizzo email che era usato per la prenotazione è valido',
	en: 'Only the email address you provided when booking is valid'
}
export const errorMsg = {
	de: 'Reservierung konnte nicht gefunden werden',
	it: 'Prenotazione non trovata',
	en: 'Reservation could not be found'
}

// Settings
export const ecar = {
	de: 'Anreise mit E-Auto',
	it: 'Arrivo con automobile elettrica',
	en: 'Arrival with electric car'
}
export const chargingStation = {
	de: 'E-Auto-Ladestation reservieren',
	it: 'Prenota la colonnina di ricarica per auto elettriche',
	en: 'Reserve e-car charging station'
}
export const chargingStationDesc = {
	de: 'Dürfen wir Ihnen einen Parkplatz bei der E-Auto-Ladestation reservieren?',
	it: 'Possiamo prenotarvi un posto alla colonnina di ricarica per auto elettriche?',
	en: 'Do you need a parking space at the e-car charging station?'
}
export const apartmentConfig = {
	de: 'Apartmentkonfigurator',
	it: 'Configuratore dell\'appartamento',
	en: 'Apartment configurator'
}
export const analcoholic = {
	de: 'Willkommensgetränk analkoholisch',
	it: 'Drink di benvenuto analcolico',
	en: 'Non-alcoholic welcome drink'
}
export const allergic = {
	de: 'Allergikerbetten',
	it: 'Letti con materassi anallergici',
	en: 'Beds with hypoallergenic mattresses'
}
export const sofaBed = {
	de: 'Schlafsofa benutzen (wir schlafen getrennt)',
	it: 'Usa il divano letto (dormiamo separati)',
	en: 'Use sofa bed (we sleep separately)'
}
export const babyBed = {
	de: 'Babybett (€ 10/Tag)',
	it: 'Culla (€ 10/Tag)',
	en: 'Cot (€ 10/Tag)'
}
export const childChair = {
	de: 'Hochstuhl',
	it: 'Seggiolone',
	en: 'High chair'
}
export const shoppingService = {
	de: 'Einkaufservice',
	it: 'Servizio di spesa',
	en: 'Shopping service'
}
export const shoppingServiceDesc = {
	de: 'Wir sorgen dafür, dass Sie vollkommen entspannt in Ihren Urlaub starten können, denn um Ihren ersten Einkauf kümmern wir uns gerne! Wählen Sie aus verschiedenen Südtiroler und italienischen Spezialitäten und freuen Sie sich bei Ihrer Ankunft auf einen gefüllten Kühl- und Vorratsschrank.',
	it: 'Iniziate la vacanza in completo relax. Alla prima spesa ci pensiamo noi! Vi proponiamo una selezione di specialità altoatesine e nazionali. Scegliete quelle che preferite e godetevi un frigo pieno all\'arrivo',
	en: 'We want you to start your holiday in total relaxation, so let us do the first shopping for you! Choose from different South Tyrolean and Italian specialities and enjoy a “full fridge” on your arrival'
}
export const specialEvent = {
	de: 'Besonderer Anlass',
	it: 'Occasione speciale',
	en: 'Special event'
}
export const specialEventDesc = {
	de: 'Egal ob Geburtstag, Hochzeitstag oder Muttertag? Wir helfen gerne!',
	it: 'Che si tratti di un compleanno, un anniversario di matrimonio o la festa della mamma? Siamo felici di aiutarvi!',
	en: 'Whether it\'s a birthday, wedding anniversary or Mother\'s Day? We\'re happy to help!'
}
export const bouquet = {
	de: 'Blumenstrauß',
	it: 'Mazzo di fiori',
	en: 'Bouquet'
}
export const cake = {
	de: 'Kuchen',
	it: 'Torta',
	en: 'Cake'
}
export const reason = {
	de: 'Anlass und Sorte',
	it: 'Motivo e tipo',
	en: 'Reason and type'
}
export const date = {
	de: 'Datum',
	it: 'Data',
	en: 'Date'
}
export const price = {
	de: 'Preis',
	it: 'Prezzo',
	en: 'Price'
}
export const breakfastService = {
	de: 'Brötchenservice',
	it: 'Servizio di pane fresco',
	en: 'Bread service'
}
export const breakfastServiceDesc = {
	de: 'Täglich, außer sonntags, beliefern wir Sie gerne mit frischen Brötchen. Bestellen Sie jetzt schon für Ihr erstes Frühstück im Dornsberg.',
	it: 'Tutti i giorni, esclusa la domenica, siamo lieti di consegnarvi panini freschi. Ordina ora per la prima colazione al Dornsberg.',
	en: 'Every day, except Sundays, we are happy to deliver fresh bread to you. Order now for your first breakfast at Dornsberg.'
}
export const dog = {
	de: 'Urlaub mit Hund',
	it: 'Vacanca con cane',
	en: 'Holiday with dog'
}
export const dogSitter = {
	de: 'Interessieren Sie sich für einen Dog Sitter Service während Ihres Aufenthalts?',
	it: 'Siete interessati ad un servizio dog sitter durante il vostro soggiorno?',
	en: 'Are you interest in a dog sitter service during your stay?'
}
export const rentalService = {
	de: 'Leihservice',
	it: 'Servizio di noleggio',
	en: 'Rental service'
}
export const rentalServiceDesc = {
	de: 'In unserem Radkeller stehen Ihnen folgende Leihartikel zur Verfügung, welche Sie jetzt schon reservieren können.',
	it: 'I seguenti articoli a noleggio sono disponibili nella nostra cantina per biciclette, che potrebbe prenotare ora.',
	en: 'The following rental items are available in our bike deposit, which you can reserve now.'
}
export const reserveDate = {
	de: 'Reservierungsdatum',
	it: 'Data di prenotazione',
	en: 'Booking date'
}
export const dayLabel = {
	de: 'Tag',
	it: 'giorno',
	en: 'day'
}
export const misc = {
	de: 'Sonstiges',
	it: 'Altri informazioni',
	en: 'Further information'
}
export const miscDesc = {
	de: 'Sind noch Wünsche offen? Dann geben Sie uns gerne Bescheid. So können wir Ihren Aufenthalt bestmöglich planen.',
	it: 'Ci sono ulteriori richieste? Fatecelo sapere, così da poter pianificare il vostro soggiorno al meglio.',
	en: 'Any wishes left? Then please let us know. This allows us to plan your stay in the best possible way.'
}
export const miscInput = {
	de: 'Wünsche und Anregungen',
	it: 'Le vostre richieste',
	en: 'Wishes and suggestions'
}
export const summaryLabel = {
	de: 'Zusammenfassung',
	it: 'Panoramica',
	en: 'Summary'
}