import React, { useState, useEffect, useMemo } from 'react';
import { motion } from 'framer-motion';
import './App.css';
import * as Constants from './constants';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import BedRoundedIcon from '@mui/icons-material/BedRounded';
import Concierge from './components/Concierge';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import LoginIcon from '@mui/icons-material/Login';

function App() {

	const [error, setError] = useState(false)
	const [loader, setLoader] = useState(true)
	const [guestData, setGuestData] = useState();
	const [state, setState] = useState(true);
	const [intro, setIntro] = useState(true);

	useEffect(() => {
		getAccess()
	}, []);

	const getAccess = (data) => {
		let content
		if(data) content = data
		else if (window.location.search.length) content = window.location.search
		else content = null
		fetch(Constants.baseUrl+'/php/access.php', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				content: content
			})
		})
		.then(res => res.json())
		.then(res => {
			if(res === 'login') setGuestData(res)
			else if(!res.data) {
				setError(true)
				setLoader(false)
				setGuestData('login')
			}
			else setGuestData(res.data.reservation)
		})
		.catch(function() {
			console.log('Fetch error');
		});
	}

	const Intro = () => {
		const variants1 = {
			open: {
				scale: [0.5,1]
			},
			closed: {
				scale: 0.5,
				opacity: 0,
				transitionEnd: {
					display: "none",
				}
			}
		}
		return (
			<motion.div
				animate={state ? 'open' : 'closed'}
				variants={variants1}
				className="intro"
				style={{ textAlign: 'center' }}
			>
				<>
					<img width={200} className="logo" src="./images/logo.svg" />
					<div className='salutation'>
						{Constants.salutation[Constants.lang]} {guestData.bookingGuest.lastName.slice(0,1).toUpperCase()}{guestData.bookingGuest.lastName.slice(1)}!
					</div>
					<br/>
					<div className='introText'>
						{Constants.introText[Constants.lang]}
					</div>
					<br/>
					{guestData.roomTypeReservations ? guestData.roomTypeReservations.map((reservation, i) => (
						<Stack key={i} direction="column" spacing={1}>
							<Chip icon={<CalendarMonthRoundedIcon />} label={new Date(reservation.arrival).toLocaleDateString(Constants.lang)+' - '+new Date(reservation.departure).toLocaleDateString(Constants.lang)} variant="outlined" />
							{reservation.statuses.map((status, i) => (
								<Chip key={i} icon={<BedRoundedIcon />} label={status.roomType.name} variant="outlined" />
							))}
						</Stack>
					)) : ''}
					<br/>
					<Button
						variant="contained"
						onClick={() => {
							setState(!state)
							setTimeout(() => setIntro(!intro),200)
						}}
					>
						{Constants.letsGo[Constants.lang]}
					</Button>
				</>
			</motion.div>
		)
	}

	const Login = () => {
		setLoader(false)
		const [code, setCode] = useState(false)
		const [mail, setMail] = useState(false)
		let input = {}
		return (
			<Box
				component="form"
				sx={{ textAlign: 'center',
					'& .MuiTextField-root': { m: 1, width: '25ch' },
				}}
				noValidate
				autoComplete="off"
				>
				<img width={200} className="logo" src="./images/logo.svg" />
				{!loader ? <>
					<div>
						<TextField
						required
						autoFocus={true}
						id="bookingCode"
						label={Constants.bookingCode[Constants.lang]}
						helperText={Constants.bookingCodeExpl[Constants.lang]}
						error={code}
						/>
						<TextField
						required
						id="bookingMail"
						label={Constants.emailLabel[Constants.lang]}
						type="email"
						helperText={Constants.bookingMailExpl[Constants.lang]}
						error={mail}
						/>
					</div>
					<div style={{ marginTop: '16px' }}>
						<Button type="submit" variant="contained" onClick={(e) => {
							e.preventDefault()
							input.code = document.querySelector('#bookingCode').value
							input.mail = document.querySelector('#bookingMail').value
							!input.code ? setCode(true) : setCode(false)
							!input.mail ? setMail(true) : setMail(false)
							if(!input.code || !input.mail) return false
							else {
								setGuestData(null)
								getAccess(input)
							}
						}}
						endIcon={<LoginIcon />}
						>Login</Button>
					</div>
				</>
				: <div style={{ marginTop: '32px' }}><CircularProgress /></div>}
				{error ?
					<div style={{ marginTop: '32px' }}>
						 <Alert sx={{ display: 'flex', justifyContent: 'center' }} severity="error">{Constants.errorMsg[Constants.lang]}</Alert>
					</div>
					:''
				}
			</Box>
		)
	}

	// views
	if(!guestData) return <div className="spinner flex-center align-center"><CircularProgress /></div>
	else if (guestData === 'login') return <Login />
	else {
		if(intro) return <Intro />
		else return <Concierge guestData={guestData} />
	}

}

export default App;